/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  editClientData: {},
  clientList: [],
  clientDocumentList: [],
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setEditClientList: (state, action) => {
      state.editClientData = action.payload;
    },
    clearClientData: (state) => {
      state.editClientData = {};
      state.clientList = {};
    },
    setClientDocumentList: (state, action) => {
      state.clientDocumentList = action.payload;
    },
  },
});
export const { setEditClientList, setClientDocumentList, clearClientData } =
  clientSlice.actions;
export default clientSlice.reducer;
