import { RouterProvider } from "react-router-dom";
import { routeObject } from "../../routes";
import { useSelector } from "react-redux";
import { ToastMessage } from "../toast-messages";
import { Suspense } from "react";
import { InitialLoadUi } from "../initial-site-load-loader";

const App = () => {
  const { message, type } = useSelector((state) => state.toast);
  // console.log = () => { };
  return (
    <>
      {message && <ToastMessage type={type} message={message} />}
      <Suspense fallback={<InitialLoadUi />}>
        <RouterProvider router={routeObject} />
      </Suspense>
    </>
  );
};

export default App;
