import { createBrowserRouter } from "react-router-dom";
import { lazy } from "react";
import * as lazyLoad from "./lazyLoadFiles";
import {
  ACCOUNTS,
  ADMIN,
  HR,
  SOFTWARE,
  MANAGER,
  SALES,
  STAFF,
  SYSTEM_ADMIN,
  LEAD,
} from "../constant/constant";
// Login
const LazySignIn = lazy(() => import("../pages/registration/sign-in"));
const LazyForgotPassword = lazy(() =>
  import("../pages/registration/forgot-password")
);
const LazyResetPassword = lazy(() =>
  import("../pages/registration/reset-password")
);

const LazyExpiredResetPasswordLink = lazy(() =>
  import("../pages/registration/reset-password/expired-link")
);

const LazyPageNotFound = lazy(() => import("../pages/page-not-found"));

const LazyAuthWrapper = lazy(() => import("../routes/AuthWrapper"));

// Main Layout
const LazyMainLayout = lazy(() => import("../pages/main-layout"));

// Dashboard
const LazyDashboard = lazy(() => import("../pages/hr/dashboard"));

// HR Module
const LazyHR = lazy(() => import("../pages/hr"));
const LazyEmployee = lazy(() => import("../pages/hr/employees"));
const LazyGetEmployee = lazy(() => import("../pages/hr/employees/getemployee"));

const LazyProfilePersonal = lazy(() =>
  import("../pages/hr/view-profile/personal")
);
const LazyProfileJob = lazy(() => import("../pages/hr/view-profile/job"));
const LazyProfileBank = lazy(() => import("../pages/hr/view-profile/bank"));

const LazyProfilePersonalDetails = lazy(() =>
  import("../pages/hr/view-profile/employee-personal-history")
);
const LazyProfileAppraisal = lazy(() =>
  import("../pages/hr/view-profile/employee-appraisal-history")
);

const LazyEmployeePersonal = lazy(() =>
  import("../pages/hr/employees/addemployee/personal")
);
const LazyEmployeeJobInfo = lazy(() =>
  import("../pages/hr/employees/addemployee/job")
);
const LazyEmployeeBankInfo = lazy(() =>
  import("../pages/hr/employees/addemployee/bank")
);
const LazyProfile = lazy(() => import("../pages/hr/view-profile"));
//Attendance
const LazyAttendance = lazy(() => import("../pages/hr/attendance"));
// Holiday Module
const LazyHoliday = lazy(() => import("../pages/hr/holiday"));
const LazyGetHoliday = lazy(() => import("../pages/hr/holiday/getholiday"));
const LazyNotFound = lazy(() => import("../components/not-found"));

// Remarks
const LazyRemarks = lazy(() => import("../pages/hr/remarks"));
// Staffings
const LazyStaffing = lazy(() => import("../pages/staffing"));
const LazyClient = lazy(() => import("../pages/staffing/clients"));
const LazyViewClients = lazy(() =>
  import("../pages/staffing/clients/view-client")
);
export const LazyAddClients = lazy(() =>
  import("../pages/staffing/clients/add-clients")
);
const LazyRequirement = lazy(() => import("../pages/staffing/job-openings"));
const LazyViewRequirement = lazy(() =>
  import("../pages/staffing/job-openings/view-requirements")
);
export const LazyAddRequirement = lazy(() =>
  import("../pages/staffing/job-openings/add-requirements")
);
export const routData = [
  {
    path: "*",
    element: <LazyNotFound />,
    role: [
      ADMIN,
      SOFTWARE,
      MANAGER,
      STAFF,
      SALES,
      LEAD,
      ACCOUNTS,
      HR,
      SYSTEM_ADMIN,
    ],
  },
  {
    path: "/",
    element: <LazySignIn />,
    role: [
      ADMIN,
      SOFTWARE,
      MANAGER,
      STAFF,
      SALES,
      LEAD,
      ACCOUNTS,
      HR,
      SYSTEM_ADMIN,
    ],
  },
  {
    path: "forgot-password",
    element: <LazyForgotPassword />,
    role: [
      ADMIN,
      SOFTWARE,
      MANAGER,
      STAFF,
      SALES,
      LEAD,
      ACCOUNTS,
      HR,
      SYSTEM_ADMIN,
    ],
  },
  {
    path: "reset-password",
    element: <LazyResetPassword />,
    role: [
      ADMIN,
      SOFTWARE,
      MANAGER,
      STAFF,
      SALES,
      LEAD,
      ACCOUNTS,
      HR,
      SYSTEM_ADMIN,
    ],
  },
  {
    path: "link-expired",
    element: <LazyExpiredResetPasswordLink />,
    role: [
      ADMIN,
      SOFTWARE,
      MANAGER,
      STAFF,
      SALES,
      LEAD,
      ACCOUNTS,
      HR,
      SYSTEM_ADMIN,
    ],
  },
  {
    path: "pagenotFound",
    element: <LazyPageNotFound />,
    role: [
      ADMIN,
      SOFTWARE,
      MANAGER,
      STAFF,
      SALES,
      LEAD,
      ACCOUNTS,
      HR,
      SYSTEM_ADMIN,
    ],
  },
  {
    path: "add-offer",
    element: <lazyLoad.LazyAddOffer />,
    role: [
      ADMIN,
      SOFTWARE,
      MANAGER,
      STAFF,
      SALES,
      LEAD,
      ACCOUNTS,
      HR,
      SYSTEM_ADMIN,
    ],
  },
  {
    path: "success",
    element: <lazyLoad.LazySuccessLayout />,
    role: [
      ADMIN,
      SOFTWARE,
      MANAGER,
      STAFF,
      SALES,
      LEAD,
      ACCOUNTS,
      HR,
      SYSTEM_ADMIN,
    ],
  },
  {
    path: "offerlink-expired",
    element: <lazyLoad.LazyOfferexpiredLink />,
    role: [
      ADMIN,
      SOFTWARE,
      MANAGER,
      STAFF,
      SALES,
      LEAD,
      ACCOUNTS,
      HR,
      SYSTEM_ADMIN,
    ],
  },
  {
    path: "/",
    element: (
      <LazyAuthWrapper>
        <LazyMainLayout />
      </LazyAuthWrapper>
    ),
    children: [
      {
        path: "hr",
        element: <LazyHR />,
        children: [
          {
            path: "dashboard",
            element: <LazyDashboard />,
            location: "/hr/dashboard",
            role: [
              ADMIN,
              SOFTWARE,
              MANAGER,
              STAFF,
              SALES,
              ACCOUNTS,
              HR,
              LEAD,
              SYSTEM_ADMIN,
            ],
          },
          {
            path: "employee",
            element: <LazyEmployee />,
            children: [
              {
                path: "",
                element: <LazyGetEmployee />,
                header: {
                  title: "Employee",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "HR",
                      navigate: null,
                    },
                    {
                      title: "Employee",
                      navigate: null,
                    },
                  ],
                },
                location: "/hr/employee",
                role: [ADMIN],
              },
              {
                path: "addemployee",
                element: <lazyLoad.LazyAdd />,
                children: [
                  {
                    path: "",
                    element: <LazyEmployeePersonal />,
                    header: {
                      title: "Employee",
                      path: [
                        {
                          title: "Home",
                          navigate: null,
                        },
                        {
                          title: "HR",
                          navigate: null,
                        },
                        {
                          title: "Employee",
                          navigate: null,
                        },
                        {
                          title: "Personal Information",
                          navigate: null,
                        },
                      ],
                    },
                    location: "/hr/employee/addemployee",
                    role: [ADMIN],
                  },
                  {
                    path: "job-info",
                    element: <LazyEmployeeJobInfo />,
                    header: {
                      title: "Employee",
                      path: [
                        {
                          title: "Home",
                          navigate: null,
                        },
                        {
                          title: "HR",
                          navigate: null,
                        },
                        {
                          title: "Employee",
                          navigate: null,
                        },
                        {
                          title: "Job Information",
                          navigate: null,
                        },
                      ],
                    },
                    location: "/hr/employee/addemployee/job-info",
                    role: [ADMIN],
                  },
                  {
                    path: "bank-info",
                    element: <LazyEmployeeBankInfo />,
                    header: {
                      title: "Employee",
                      path: [
                        {
                          title: "Home",
                          navigate: null,
                        },
                        {
                          title: "HR",
                          navigate: null,
                        },
                        {
                          title: "Employee",
                          navigate: null,
                        },
                        {
                          title: "Bank Account and Salary Information",
                          navigate: null,
                        },
                      ],
                    },
                    location: "/hr/employee/addemployee/bank-info",
                    role: [ADMIN],
                  },
                ],
                header: "Employee",
                location: "/hr/employee/addemployee",
                role: [ADMIN],
              },
              {
                path: "*",
                element: <LazyNotFound />,
                role: [
                  ADMIN,
                  SOFTWARE,
                  MANAGER,
                  STAFF,
                  SALES,
                  ACCOUNTS,
                  HR,
                  LEAD,
                  SYSTEM_ADMIN,
                ],
              },
            ],
            header: "Employee",
            location: "/hr/employee",
            role: [ADMIN],
          },
          {
            path: "remarks",
            element: <LazyRemarks />,
            header: {
              title: "Remarks",
              path: [
                {
                  title: "Home",
                  navigate: null,
                },
                {
                  title: "HR",
                  navigate: null,
                },
                {
                  title: "remarks",
                  navigate: null,
                },
              ],
            },
            location: "/hr/remarks",
            role: [HR, MANAGER],
          },
          {
            path: "profile/*",
            element: <LazyProfile />,
            title: "View Employee Details",
            location: "/hr/profile",
            children: [
              {
                path: "personal/:id",
                element: <LazyProfilePersonal />,
                header: {
                  title: "View Employee Details",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "HR",
                      navigate: null,
                    },
                    {
                      title: "Employee",
                      navigate: null,
                    },
                    {
                      title: "Personal Information",
                      navigate: null,
                    },
                  ],
                },
                location: "/hr/profile/personal/:id",
                role: [ADMIN, MANAGER],
              },
              {
                path: "profilejob/:id",
                element: <LazyProfileJob />,
                header: {
                  title: "View Employee Details",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "HR",
                      navigate: null,
                    },
                    {
                      title: "Employee",
                      navigate: null,
                    },
                    {
                      title: "Job Information",
                      navigate: null,
                    },
                  ],
                },
                location: "/hr/profile/profilejob/:id",
                role: [ADMIN, MANAGER],
              },
              {
                path: "profilebank/:id",
                element: <LazyProfileBank />,
                header: {
                  title: "View Employee Details",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "HR",
                      navigate: null,
                    },
                    {
                      title: "Employee",
                      navigate: null,
                    },
                    {
                      title: "Bank Information",
                      navigate: null,
                    },
                  ],
                },
                location: "/hr/profile/profilebank/:id",
                role: [ADMIN, MANAGER],
              },
              {
                path: "*",
                element: <LazyNotFound />,
                role: [
                  ADMIN,
                  SOFTWARE,
                  MANAGER,
                  STAFF,
                  SALES,
                  ACCOUNTS,
                  HR,
                  LEAD,
                  SYSTEM_ADMIN,
                ],
              },
              {
                path: "personalDetails/:id",
                element: <LazyProfilePersonalDetails />,
                header: {
                  title: "View Employee Personal Details",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "HR",
                      navigate: null,
                    },
                    {
                      title: "Employee",
                      navigate: null,
                    },
                    {
                      title: "Personal Details",
                      navigate: null,
                    },
                  ],
                },
                location: "/hr/profile/personalDetails/:id",
                role: [ADMIN, MANAGER],
              },
              {
                path: "profileAppraisal/:id",
                element: <LazyProfileAppraisal />,
                header: {
                  title: "View Employee Appraisal Details",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "HR",
                      navigate: null,
                    },
                    {
                      title: "Employee",
                      navigate: null,
                    },
                    {
                      title: "Job Information",
                      navigate: null,
                    },
                  ],
                },
                location: "/hr/profile/profileAppraisal/:id",
                role: [ADMIN, MANAGER],
              },
            ],
            role: [ADMIN, MANAGER],
          },
          {
            path: "attendance",
            element: <LazyAttendance />,
            header: {
              title: "Attendance",
              path: [
                {
                  title: "Home",
                  navigate: null,
                },
                {
                  title: "HR",
                  navigate: null,
                },
                {
                  title: "Attendance",
                  navigate: null,
                },
              ],
            },
            location: "/hr/attendance",
            role: [
              ADMIN,
              SOFTWARE,
              MANAGER,
              STAFF,
              SALES,
              ACCOUNTS,
              HR,
              LEAD,
              SYSTEM_ADMIN,
            ],
          },
          {
            path: "holiday/*",
            element: <LazyHoliday />,
            children: [
              {
                path: "",
                element: <LazyGetHoliday />,
                header: {
                  title: `Holiday ${new Date().getFullYear()}`,

                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "HR",
                      navigate: null,
                    },
                    {
                      title: "Holiday",
                      navigate: null,
                    },
                  ],
                },
                location: "/hr/holiday",
                role: [
                  ADMIN,
                  SOFTWARE,
                  MANAGER,
                  STAFF,
                  SALES,
                  ACCOUNTS,
                  HR,
                  LEAD,
                  SYSTEM_ADMIN,
                ],
              },
              {
                path: "*",
                element: <LazyNotFound />,
                role: [
                  ADMIN,
                  SOFTWARE,
                  MANAGER,
                  STAFF,
                  SALES,
                  ACCOUNTS,
                  HR,
                  LEAD,
                  SYSTEM_ADMIN,
                ],
              },
            ],
            title: `Holiday ${new Date().getFullYear()}`,
            location: "/hr/holiday",
            role: [
              ADMIN,
              SOFTWARE,
              MANAGER,
              STAFF,
              SALES,
              ACCOUNTS,
              HR,
              LEAD,
              SYSTEM_ADMIN,
            ],
          },
          {
            path: "leave-break",
            element: <lazyLoad.LazyLeaveBreakTabs />,
            children: [
              {
                path: "leave",
                element: <lazyLoad.LazyLeave />,
                header: {
                  title: "Leave & Break",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "HR",
                      navigate: null,
                    },
                    {
                      title: "Leave & Break",
                      navigate: null,
                    },
                    {
                      title: "Leave",
                      navigate: null,
                    },
                  ],
                },
                location: "/hr/leave-break/leave",
                role: [
                  ADMIN,
                  SOFTWARE,
                  MANAGER,
                  STAFF,
                  SALES,
                  ACCOUNTS,
                  HR,
                  LEAD,
                  SYSTEM_ADMIN,
                ],
              },
              {
                path: "break",
                element: <lazyLoad.LazyBreak />,
                header: {
                  title: "Leave & Break",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "HR",
                      navigate: null,
                    },
                    {
                      title: "Leave & Break",
                      navigate: null,
                    },
                    {
                      title: "Break",
                      navigate: null,
                    },
                  ],
                },
                location: "/hr/leave-break/break",
              },
            ],
            header: "Leave & Break",
            location: "/hr/leave-break",
            role: [
              ADMIN,
              SOFTWARE,
              MANAGER,
              STAFF,
              SALES,
              ACCOUNTS,
              HR,
              LEAD,
              SYSTEM_ADMIN,
            ],
          },
          {
            path: "salary-slip",
            element: <lazyLoad.LazySalarySlip />,
            header: {
              title: "Salary Slip",
              path: [
                {
                  title: "Home",
                  navigate: null,
                },
                {
                  title: "HR",
                  navigate: null,
                },
                {
                  title: "Salary Slip",
                  navigate: null,
                },
              ],
            },

            location: "/hr/salary-slip",
            role: [
              SOFTWARE,
              MANAGER,
              STAFF,
              SALES,
              ACCOUNTS,
              LEAD,
              HR,
              SYSTEM_ADMIN,
            ],
          },
          {
            path: "appraisal/*",
            element: <lazyLoad.LazyAppraisal />,
            children: [
              {
                path: "appraisal-list/*",
                element: <lazyLoad.LazyAppraisalList />,
                children: [
                  {
                    path: "",
                    element: <lazyLoad.LazyGetAppraisalList />,
                    header: {
                      title: "Appraisal",
                      path: [
                        {
                          title: "Home",
                          navigate: null,
                        },
                        {
                          title: "HR",
                          navigate: null,
                        },
                        {
                          title: "Appraisal",
                          navigate: null,
                        },
                      ],
                    },
                    location: "/hr/appraisal/appraisal-list",
                    role: [
                      ADMIN,
                      SOFTWARE,
                      MANAGER,
                      STAFF,
                      SALES,
                      ACCOUNTS,
                      HR,
                      LEAD,
                      SYSTEM_ADMIN,
                    ],
                  },
                  {
                    path: "view-appraisal",
                    element: <lazyLoad.LazyViewAppraisal />,
                    header: {
                      title: "Appraisal",
                      path: [
                        {
                          title: "Home",
                          navigate: null,
                        },
                        {
                          title: "HR",
                          navigate: null,
                        },
                        {
                          title: "Appraisal",
                          navigate: null,
                        },
                      ],
                    },
                    location: "/hr/appraisal/appraisal-list/view-appraisal",
                    role: [
                      ADMIN,
                      SOFTWARE,
                      MANAGER,
                      STAFF,
                      SALES,
                      ACCOUNTS,
                      HR,
                      LEAD,
                      SYSTEM_ADMIN,
                    ],
                  },
                  {
                    path: "rating-form-appraisal",
                    element: <lazyLoad.LazyFormAppraisal />,
                    header: {
                      title: "Appraisal",
                      path: [
                        {
                          title: "Home",
                          navigate: null,
                        },
                        {
                          title: "HR",
                          navigate: null,
                        },
                        {
                          title: "Appraisal",
                          navigate: null,
                        },
                      ],
                    },
                    location: "/hr/appraisal/appraisal-list/view-appraisal",

                    children: [
                      {
                        path: "",
                        element: <lazyLoad.LazykeyPerfomingIndicators />,
                        header: {
                          title: "Appraisal",
                          path: [
                            {
                              title: "Home",
                              navigate: null,
                            },
                            {
                              title: "HR",
                              navigate: null,
                            },
                            {
                              title: "Appraisal",
                              navigate: null,
                            },
                            {
                              title: "Key Performing Indicators",
                              navigate: null,
                            },
                          ],
                        },
                        location:
                          "/hr/appraisal/appraisal-list/rating-form-appraisal",
                      },
                      {
                        path: "feedBack-comments",
                        element: <lazyLoad.LazyFeedBackComments />,
                        header: {
                          title: "Appraisal",
                          path: [
                            {
                              title: "Home",
                              navigate: null,
                            },
                            {
                              title: "HR",
                              navigate: null,
                            },
                            {
                              title: "Appraisal",
                              navigate: null,
                            },
                            {
                              title: "Feedback & Comments",
                              navigate: null,
                            },
                          ],
                        },
                        location:
                          "/hr/appraisal/appraisal-list/rating-form-appraisal/feedback-comments",
                      },
                    ],
                  },
                  {
                    path: "view-rating-appraisal",
                    element: <lazyLoad.LazyViewRatingAppraisal />,
                    header: {
                      title: "Appraisal",
                      path: [
                        {
                          title: "Home",
                          navigate: null,
                        },
                        {
                          title: "HR",
                          navigate: null,
                        },
                        {
                          title: "Appraisal",
                          navigate: null,
                        },
                      ],
                    },

                    location:
                      "/hr/appraisal/appraisal-list/view-rating-appraisal",
                  },
                ],
                header: "Appraisal",
                location: "/hr/appraisal/appraisal-list",
              },
            ],
            header: "Appraisal",
            location: "/hr/appraisal",
            role: [
              ADMIN,
              SOFTWARE,
              MANAGER,
              STAFF,
              SALES,
              ACCOUNTS,
              HR,
              LEAD,
              SYSTEM_ADMIN,
            ],
          },
          {
            path: "payroll/*",
            element: <lazyLoad.LazyPayRoll />,
            children: [
              {
                path: "",
                element: <lazyLoad.LazyGetPayRoll />,
                role: [
                  ADMIN,
                  SOFTWARE,
                  MANAGER,
                  STAFF,
                  SALES,
                  ACCOUNTS,
                  HR,
                  LEAD,
                  SYSTEM_ADMIN,
                ],
              },
            ],

            header: {
              title: "Payroll",
              path: [
                {
                  title: "Home",
                  navigate: null,
                },
                {
                  title: "HR",
                  navigate: null,
                },
                {
                  title: "Payroll",
                  navigate: null,
                },
              ],
            },
            location: "/hr/payroll",
            role: [
              ADMIN,
              SOFTWARE,
              MANAGER,
              STAFF,
              SALES,
              ACCOUNTS,
              HR,
              LEAD,
              SYSTEM_ADMIN,
            ],
          },
          {
            path: "documents",
            element: <lazyLoad.LazyDocumentsTabs />,
            children: [
              {
                path: "confirmation-letter",
                element: <lazyLoad.LazyConfirmationLetter />,
                header: {
                  title: "Documents",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "HR",
                      navigate: null,
                    },
                    {
                      title: "Documents",
                      navigate: null,
                    },
                    {
                      title: "Confirmation Letter",
                      navigate: null,
                    },
                  ],
                },
                location: "/hr/documents/confirmation-letter",
                role: [
                  ADMIN,
                  SOFTWARE,
                  MANAGER,
                  STAFF,
                  SALES,
                  ACCOUNTS,
                  HR,
                  LEAD,
                  SYSTEM_ADMIN,
                ],
              },
              {
                path: "increment-letter",
                element: <lazyLoad.LazyIncrementLetter />,
                header: {
                  title: "Documents",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "HR",
                      navigate: null,
                    },
                    {
                      title: "Documents",
                      navigate: null,
                    },
                    {
                      title: "Increment Letter",
                      navigate: null,
                    },
                  ],
                },
                location: "/hr/documents/increment-letter",
                role: [
                  ADMIN,
                  SOFTWARE,
                  MANAGER,
                  STAFF,
                  SALES,
                  ACCOUNTS,
                  HR,
                  LEAD,
                  SYSTEM_ADMIN,
                ],
              },
              {
                path: "relieving-experience-letter",
                element: <lazyLoad.LazyRelievingExperienceLetter />,
                header: {
                  title: "Documents",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "HR",
                      navigate: null,
                    },
                    {
                      title: "Documents",
                      navigate: null,
                    },
                    {
                      title: "Relieving/Experience/Letter",
                      navigate: null,
                    },
                  ],
                },
                location: "/hr/documents/relieving-experience-letter",
                role: [ADMIN],
              },
            ],
            header: "Documents",
            location: "/hr/documents/confirmation-letter",
            role: [
              ADMIN,
              SOFTWARE,
              MANAGER,
              STAFF,
              SALES,
              ACCOUNTS,
              HR,
              LEAD,
              SYSTEM_ADMIN,
            ],
          },
          {
            path: "hr-policy-handBook",
            element: <lazyLoad.LazyPolicyHandBookTabs />,
            children: [
              {
                path: "handBook",
                element: <lazyLoad.LazyHandBook />,
                header: {
                  title: "HR Policy & Hand Book",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "HR",
                      navigate: null,
                    },
                    {
                      title: "HR Policy & Hand Book",
                      navigate: null,
                    },
                    {
                      title: "Hand Book",
                      navigate: null,
                    },
                  ],
                },
                location: "/hr/hr-policy-handBook/handBook",
                role: [
                  ADMIN,
                  SOFTWARE,
                  MANAGER,
                  STAFF,
                  SALES,
                  ACCOUNTS,
                  HR,
                  LEAD,
                  SYSTEM_ADMIN,
                ],
              },
              {
                path: "hr-policy",
                element: <lazyLoad.LazyPolicy />,
                header: {
                  title: "HR Policy & Hand Book",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "HR",
                      navigate: null,
                    },
                    {
                      title: "HR Policy & Hand Book",
                      navigate: null,
                    },
                    {
                      title: "HR Policy",
                      navigate: null,
                    },
                  ],
                },
                location: "/hr/hr-policy-handBook/hr-policy",
              },
            ],
            header: "HR Policy & Hand Book",
            location: "/hr/hr-policy-handBook",
            role: [
              ADMIN,
              SOFTWARE,
              MANAGER,
              STAFF,
              SALES,
              ACCOUNTS,
              HR,
              LEAD,
              SYSTEM_ADMIN,
            ],
          },
          {
            path: "ticket-management",
            element: <lazyLoad.LazyTicketManagement />,
            header: {
              title: "Ticket Management",
              path: [
                {
                  title: "Home",
                  navigate: null,
                },
                {
                  title: "HR",
                  navigate: null,
                },
                {
                  title: "Ticket Management",
                  navigate: null,
                },
              ],
            },
            location: "/hr/ticket-management",
            role: [
              ADMIN,
              SOFTWARE,
              MANAGER,
              STAFF,
              SALES,
              ACCOUNTS,
              HR,
              LEAD,
              SYSTEM_ADMIN,
            ],
          },
          {
            path: "master",
            element: <lazyLoad.LazyMasterTabs />,
            children: [
              {
                path: "department",
                element: <lazyLoad.LazyDepartment />,
                header: {
                  title: "Master",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "HR",
                      navigate: null,
                    },
                    {
                      title: "Master",
                      navigate: null,
                    },
                    {
                      title: "Department",
                      navigate: null,
                    },
                  ],
                },
                location: "/hr/master/department",
                role: [ADMIN],
              },
              {
                path: "team",
                element: <lazyLoad.LazyTeam />,
                header: {
                  title: "Master",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "HR",
                      navigate: null,
                    },
                    {
                      title: "Master",
                      navigate: null,
                    },
                    {
                      title: "Team",
                      navigate: null,
                    },
                  ],
                },
                location: "/hr/master/team",
                role: [ADMIN],
              },
            ],
            header: "Master",
            location: "/hr/master/team",
            role: [ADMIN],
          },
        ],
      },

      {
        path: "staffing",
        element: <LazyStaffing />,
        children: [
          {
            path: "",
            element: <LazyRequirement />,
            children: [
              {
                path: "job-openings",
                element: <LazyRequirement />,
                children: [
                  {
                    path: "",
                    element: <LazyViewRequirement />,
                    header: {
                      title: "Requirements",
                      path: [
                        {
                          title: "Home",
                          navigate: null,
                        },
                        {
                          title: "Staffing",
                          navigate: null,
                        },
                        {
                          title: "Job Openings",
                          navigate: null,
                        },
                        {
                          title: "View Requirement",
                          navigate: null,
                        },
                      ],
                    },

                    location: "/staffing/job-openings",
                    role: [ADMIN, MANAGER, STAFF, LEAD, HR],
                  },
                  {
                    path: "add-requirements",
                    element: <LazyAddRequirement />,
                    header: {
                      title: "Requirements",
                      path: [
                        {
                          title: "Home",
                          navigate: null,
                        },
                        {
                          title: "Staffing",
                          navigate: null,
                        },
                        {
                          title: "Job Openings",
                          navigate: null,
                        },
                        {
                          title: "Add Requirement",
                          navigate: null,
                        },
                      ],
                    },
                    location: "/staffing/job-openings/add-requirements",
                    role: [ADMIN, MANAGER, STAFF, LEAD, HR],
                  },
                  {
                    path: "",
                    element: <lazyLoad.LazyJobOpeningProcess />,
                    children: [
                      {
                        path: "upload-resume/:id",
                        element: <lazyLoad.LazyUploadResume />,
                        header: {
                          title: "Requirements",
                          path: [
                            {
                              title: "Home",
                              navigate: null,
                            },
                            {
                              title: "Staffing",
                              navigate: null,
                            },
                            {
                              title: "Job Openings",
                              navigate: null,
                            },
                            {
                              title: "Upload Resume",
                              navigate: null,
                            },
                          ],
                        },
                        children: [
                          {
                            path: "",
                            element: <lazyLoad.LazyViewUploadedResume />,
                            header: {
                              title: "Requirements",
                              path: [
                                {
                                  title: "Home",
                                  navigate: null,
                                },
                                {
                                  title: "Staffing",
                                  navigate: null,
                                },
                                {
                                  title: "Job Openings",
                                  navigate: null,
                                },
                                {
                                  title: "Upload Resume",
                                  navigate: null,
                                },
                              ],
                            },
                            location:
                              "/staffing/job-openings/upload-resume/:id",
                            role: [ADMIN, MANAGER, STAFF, HR],
                          },
                          {
                            path: "add-closing-block",
                            element: <lazyLoad.LazyAddClosingBlock />,
                            header: {
                              title: "Requirements",
                              path: [
                                {
                                  title: "Home",
                                  navigate: null,
                                },
                                {
                                  title: "Staffing",
                                  navigate: null,
                                },
                                {
                                  title: "Job Openings",
                                  navigate: null,
                                },
                                {
                                  title: "Upload Resume",
                                  navigate: null,
                                },
                                {
                                  title: "Add Closing Block",
                                  navigate: null,
                                },
                              ],
                            },
                            location:
                              "/staffing/job-openings/upload-resume/:id/add-closing-block",
                            role: [ADMIN, MANAGER, STAFF, HR],
                          },
                        ],
                        location: "/staffing/job-openings/upload-resume/:id",
                        role: [ADMIN, MANAGER, STAFF, LEAD, HR],
                      },
                      {
                        path: "set-interview/:id",
                        element: <lazyLoad.LazySetInterView />,
                        header: {
                          title: "Requirements",
                          path: [
                            {
                              title: "Home",
                              navigate: null,
                            },
                            {
                              title: "Staffing",
                              navigate: null,
                            },
                            {
                              title: "Job Openings",
                              navigate: null,
                            },
                            {
                              title: "Set Interview",
                              navigate: null,
                            },
                          ],
                        },
                        location: "/staffing/job-openings/set-interview/:id",
                        role: [ADMIN, MANAGER, STAFF, LEAD, HR],
                      },
                      {
                        path: "job-offer/:id",
                        element: <lazyLoad.LazyJobOffer />,
                        header: {
                          title: "Requirements",
                          path: [
                            {
                              title: "Home",
                              navigate: null,
                            },
                            {
                              title: "Staffing",
                              navigate: null,
                            },
                            {
                              title: "Job Openings",
                              navigate: null,
                            },
                            {
                              title: "Job Offer",
                              navigate: null,
                            },
                          ],
                        },
                        location: "/staffing/job-openings/job-offer/:id",
                        role: [ADMIN, MANAGER, STAFF, LEAD, HR],
                        children: [
                          {
                            path: "",
                            element: <lazyLoad.LazyJobOfferList />,
                            location: "/staffing/job-openings/job-offer/:id",
                            role: [ADMIN, MANAGER, STAFF, LEAD, HR],
                          },
                          {
                            path: "edit-offer",
                            element: <lazyLoad.LazyAddOffer />,
                            location:
                              "/staffing/job-openings/job-offer/:id/edit-offer",
                            role: [ADMIN, MANAGER, STAFF, LEAD, HR],
                          },
                        ],
                      },
                    ],
                    header: "Requirements",
                    location: "/staffing/job-openings/upload-resume",
                  },
                ],
                header: {
                  title: "Requirements",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "Staffing",
                      navigate: null,
                    },
                    {
                      title: "Job Openings",
                      navigate: null,
                    },
                    {
                      title: "View Requirements",
                      navigate: null,
                    },
                  ],
                },
                location: "/staffing/job-openings",
                role: [ADMIN, MANAGER, STAFF, LEAD, HR],
              },
            ],
            header: "Requirements",
            location: "/staffing/job-openings",
            role: [ADMIN, MANAGER, STAFF, LEAD, HR],
          },

          {
            path: "clients",
            element: <LazyClient />,
            children: [
              {
                path: "",
                element: <LazyViewClients />,
                header: {
                  title: "Clients",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "Staffing",
                      navigate: null,
                    },
                    {
                      title: "Clients",
                      navigate: null,
                    },
                  ],
                },
                location: "/staffing/clients",
                role: [ADMIN, MANAGER],
              },
              {
                path: "add-clients",
                element: <LazyAddClients />,
                children: [],
                header: {
                  title: "Clients",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "Staffing",
                      navigate: null,
                    },
                    {
                      title: "Clients",
                      navigate: null,
                    },
                    {
                      title: "Add Client",
                      navigate: null,
                    },
                  ],
                },
                location: "/staffing/clients/add-clients",
                role: [ADMIN, MANAGER],
              },
              {
                path: "*",
                element: <LazyNotFound />,
                role: [ADMIN, MANAGER],
              },
            ],
            header: "Clients",
            location: "/staffing/clients",
            role: [ADMIN, MANAGER],
          },
          {
            path: "activity",
            element: <lazyLoad.LazyActivityTabs />,
            children: [
              {
                path: "target-submission",
                element: <lazyLoad.LazyTargetSubmission />,
                header: {
                  title: "Activity",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "Staffing",
                      navigate: null,
                    },
                    {
                      title: "Activity",
                      navigate: null,
                    },
                    {
                      title: "Target/Submission",
                      navigate: null,
                    },
                  ],
                },
                location: "/staffing/activity/target-submission",
                role: [ADMIN, MANAGER, LEAD],
              },
              {
                path: "offer-hire",
                element: <lazyLoad.LazyOfferHire />,
                header: {
                  title: "Activity",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "Staffing",
                      navigate: null,
                    },
                    {
                      title: "Activity",
                      navigate: null,
                    },
                    {
                      title: "Offer/Hire",
                      navigate: null,
                    },
                  ],
                },
                location: "/staffing/activity/offer-hire",
                role: [ADMIN, LEAD, MANAGER],
              },
              {
                path: "search-resume",
                element: <lazyLoad.LazySearchResume />,
                header: {
                  title: "Activity",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "Staffing",
                      navigate: null,
                    },
                    {
                      title: "Activity",
                      navigate: null,
                    },
                    {
                      title: "Search Resume",
                      navigate: null,
                    },
                  ],
                },
                location: "/staffing/activity/search-resume",
                role: [ADMIN, LEAD, MANAGER],
              },
            ],
            header: "Activity",
            location: "/staffing/activity/target-submission",
            role: [ADMIN, LEAD, MANAGER],
          },
        ],
      },

      {
        path: "*",
        element: <LazyNotFound />,
        role: [
          ADMIN,
          SOFTWARE,
          MANAGER,
          STAFF,
          SALES,
          ACCOUNTS,
          HR,
          LEAD,
          SYSTEM_ADMIN,
        ],
      },
    ],
  },
];
export const routeObject = createBrowserRouter(routData);
