import { lazy } from "react";

// success layout
export const LazySuccessLayout = lazy(() =>
  import("../components/success-layout")
);

export const LazyAdd = lazy(() => import("../pages/hr/employees/addemployee"));
// Leave Module
export const LazyLeaveBreakTabs = lazy(() =>
  import("../pages/hr/leave-break/LeaveBreakTabs")
);
export const LazyLeave = lazy(() =>
  import("../pages/hr/leave-break/leave/leave-list")
);
export const LazyBreak = lazy(() =>
  import("../pages/hr/leave-break/break/break-list")
);

export const LazySalarySlip = lazy(() => import("../pages/hr/salary-slip"));
// Appraisal Module
export const LazyAppraisal = lazy(() => import("../pages/hr/appraisal"));
export const LazyAppraisalList = lazy(() =>
  import("../pages/hr/appraisal/appraisal-list")
);
export const LazyGetAppraisalList = lazy(() =>
  import("../pages/hr/appraisal/appraisal-list/get-appraisal")
);
export const LazyViewAppraisal = lazy(() =>
  import("../pages/hr/appraisal/appraisal-list/view-appraisal")
);
export const LazyViewRatingAppraisal = lazy(() =>
  import("../pages/hr/appraisal/appraisal-list/view-rating-appraisal")
);

export const LazyFormAppraisal = lazy(() =>
  import("../pages/hr/appraisal/appraisal-list/rating-form-appraisal")
);
export const LazykeyPerfomingIndicators = lazy(() =>
  import(
    "../pages/hr/appraisal/appraisal-list/rating-form-appraisal/key-perfom-indicators"
  )
);
export const LazyFeedBackComments = lazy(() =>
  import(
    "../pages/hr/appraisal/appraisal-list/rating-form-appraisal/feedback-comments"
  )
);

// Payroll Module...

export const LazyPayRoll = lazy(() => import("../pages/hr/payroll"));
export const LazyGetPayRoll = lazy(() =>
  import("../pages/hr/payroll/get-payroll")
);
export const LazyAddPayRoll = lazy(() =>
  import("../pages/hr/payroll/add-payroll")
);
// HR Policy Hand Book
export const LazyPolicyHandBookTabs = lazy(() =>
  import("../pages/hr/hr-policy-handBook/PolicyHandBookTabs")
);
export const LazyHandBook = lazy(() =>
  import("../pages/hr/hr-policy-handBook/hr-handBook")
);
export const LazyPolicy = lazy(() =>
  import("../pages/hr/hr-policy-handBook/hr-policy")
);

// Documents
export const LazyDocumentsTabs = lazy(() =>
  import("../pages/hr/documents/documentsTabs")
);
export const LazyConfirmationLetter = lazy(() =>
  import("../pages/hr/documents/confirmation-letter")
);
export const LazyIncrementLetter = lazy(() =>
  import("../pages/hr/documents/increment-letter")
);
export const LazyRelievingExperienceLetter = lazy(() =>
  import("../pages/hr/documents/relieving-experience-letter")
);
// Ticket management
export const LazyTicketManagement = lazy(() =>
  import("../pages/hr/ticket-management")
);
// Master
export const LazyMasterTabs = lazy(() =>
  import("../pages/hr/master/masterTabs")
);
export const LazyDepartment = lazy(() =>
  import("../pages/hr/master/department")
);
export const LazyTeam = lazy(() => import("../pages/hr/master/team/team-list"));

// Staffing Job-Opening Process
export const LazyJobOpeningProcess = lazy(() =>
  import("../pages/staffing/job-openings/job-opening-process")
);
export const LazyUploadResume = lazy(() =>
  import("../pages/staffing/job-openings/job-opening-process/upload-resume")
);
export const LazyViewUploadedResume = lazy(() =>
  import(
    "../pages/staffing/job-openings/job-opening-process/upload-resume/view-uploaded-resume"
  )
);
export const LazySetInterView = lazy(() =>
  import("../pages/staffing/job-openings/job-opening-process/set-interview")
);
export const LazyJobOffer = lazy(() =>
  import("../pages/staffing/job-openings/job-opening-process/job-offer")
);

export const LazyJobOfferList = lazy(() =>
  import(
    "../pages/staffing/job-openings/job-opening-process/job-offer/view-offer"
  )
);

export const LazyAddClosingBlock = lazy(() =>
  import("../pages/staffing/job-openings/job-opening-process/add-resume")
);

// Staffing activities
export const LazyActivityTabs = lazy(() =>
  import("../pages/staffing/activity/activityTabs")
);
export const LazyTargetSubmission = lazy(() =>
  import("../pages/staffing/activity/target-submission")
);
export const LazyOfferHire = lazy(() =>
  import("../pages/staffing/activity/offer-hire")
);
export const LazySearchResume = lazy(() =>
  import("../pages/staffing/activity/search-resume")
);

//  Job-Opening-Offer
export const LazyAddOffer = lazy(() =>
  import(
    "../pages/staffing/job-openings/job-opening-process/job-offer/add-offer"
  )
);

export const LazyOfferexpiredLink = lazy(() =>
  import(
    "../pages/staffing/job-openings/job-opening-process/job-offer/offer-expired-link"
  )
);
