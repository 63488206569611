// eslint-disable-next-line import/namespace
import { combineReducers } from "@reduxjs/toolkit";
import toastreducer, {
  initialState as toastInitialState,
} from "../features/toast/toastSlice";
import masterReducer, {
  initialState as masterInitalState,
} from "../features/master/masterSlice";

import employeereducer, {
  initialState as employeeInitialState,
} from "../features/employee/employeeSlice";
import leaveReducer, {
  initialState as leaveInitialState,
} from "../features/leave-break/leaveSlice";
import breakReducer, {
  initialState as breakInitialState,
} from "../features/leave-break/breakSlice";
import registrationReducer, {
  initialState as regeistrationInitialState,
} from "../features/regeistration/registrationSlice";
import attendenceReducer, {
  initialState as attedenceInitialState,
} from "../features/attedence/attendenceSlice";
import appraisalReducer, {
  initialState as appraisalInitalState,
} from "../features/appraisal/appraisalSlice";
import ticketReducer, {
  initialState as ticketInitalState,
} from "../features/ticket-management/ticketSlice";

import payrollReducer, {
  initialState as payrollInitalState,
} from "../features/payroll/payrollSlice";
// Staffing Client
import clientReducer, {
  initialState as clientInitalState,
} from "../features/client/clientSlice";
import activityReducer, {
  initialState as activityInitalState,
} from "../features/activity/activitySlice";

const rootReducer = (state, action) => {
  if (action.type === "RESET_STATE") {
    state = {
      toast: toastInitialState,
      master: masterInitalState,
      employee: employeeInitialState,
      leave: leaveInitialState,
      break: breakInitialState,
      registration: regeistrationInitialState,
      attendence: attedenceInitialState,
      appraisal: appraisalInitalState,
      ticket: ticketInitalState,
      payroll: payrollInitalState,
      client: clientInitalState,
      activity: activityInitalState,
    };
  }
  return combineReducers({
    toast: toastreducer,
    master: masterReducer,
    employee: employeereducer,
    leave: leaveReducer,
    break: breakReducer,
    registration: registrationReducer,
    attendence: attendenceReducer,
    appraisal: appraisalReducer,
    ticket: ticketReducer,
    payroll: payrollReducer,
    client: clientReducer,
    activity: activityReducer,
  })(state, action);
};

export default rootReducer;
