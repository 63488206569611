/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  ticketList: {},
  editTicketData: {},
  addTicketData: {},
  ticketListData: [],
};

const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    getTicketList: (state, action) => {
      state.ticketList = action.payload;
    },

    setAddTicketData: (state, action) => {
      state.addTicketData = action.payload;
    },
    clearTicketData: (state) => {
      state.editTicketData = {};
      state.ticketList = {};
    },
    setTicketList: (state, action) => {
      state.ticketListData = action.payload;
    },
  },
});
export const {
  getTicketList,
  clearTicketData,
  setAddTicketData,
  setTicketList,
} = ticketSlice.actions;
export default ticketSlice.reducer;
