// system roles list
const systemRoles = {
  ADMIN: "Admin",
  SOFTWARE: "Software",
  MANAGER: "Manager",
  STAFF: "Staffing",
  SALES: "Sales",
  ACCOUNTS: "Account",
  HR: "HR",
  LEAD: "Lead",
  SYSTEM_ADMIN: "System Admin",
};

const systemDepartment = {
  STAFFING_DEPARTMENT: "Staffing",
  SOFTWARE_DEPARTMENT: "Software",
  ACCOUNTS_DEPARTMENT: "Account",
  HR_DEPARTMENT: "HR",
  SALES_DEPARTMENT: "Sales",
  SYSTEM_ADMIN_DEPARTMENT: "System Admin",
  SOCIAL_MEDIA_DEPARTMENT: "Social media",
};

const sysytemModule = {
  HR_MDL: "HR",
  STAFF_MDL: "STAFF",
};

export const {
  STAFFING_DEPARTMENT,
  SOFTWARE_DEPARTMENT,
  ACCOUNTS_DEPARTMENT,
  HR_DEPARTMENT,
  SALES_DEPARTMENT,
  SYSTEM_ADMIN_DEPARTMENT,
  SOCIAL_MEDIA_DEPARTMENT,
} = systemDepartment;

export const {
  ADMIN,
  SOFTWARE,
  MANAGER,
  STAFF,
  SALES,
  ACCOUNTS,
  HR,
  LEAD,
  SYSTEM_ADMIN,
} = systemRoles;
export const empRoles = [
  SOFTWARE,
  MANAGER,
  STAFF,
  SALES,
  ACCOUNTS,
  HR,
  SYSTEM_ADMIN,
  LEAD,
];

export const { HR_MDL, STAFF_MDL } = sysytemModule;
